* {
  margin: 0;
  padding: 0;
}

html,
body {
  min-height: 100vh;
  display: flex;
  flex: 1;
  background-color: #003d33;
}
#root {
  display: flex;
  flex: 1;
}

h1 {
  font-weight: bold !important;
}
h2 {
  font-weight: bold !important;
}
h3 {
  font-weight: bold !important;
}
h4 {
  font-weight: bold !important;
}
h5 {
  font-weight: bold !important;
}
p {
  font-weight: bold !important;
  margin: 0 !important;
}

.row {
  --bs-gutter-x: 0;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  background-color: transparent !important;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}
