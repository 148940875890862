.icon-container {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.header {
  height: 125px;
  background: rgb(255, 63, 41);
  overflow: hidden;
}

.content {
  background-color: white;
  text-align: center;
  overflow: hidden;
  padding: 20px;
}
